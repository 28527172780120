<template>
  <div class='group-form'>
    <a-form-model ref='groupForm' @submit.native.prevent :model="data" :rules='rules' :label-col="{span:8}" :wrapper-col="{span: 16}">
      <a-form-model-item label="分组名" prop='name'>
        <a-input v-model="data.name" placeholder="分组名称">
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="排序号" prop='seqNum'>
        <a-input v-model="data.seqNum" placeholder="排序号（1-1000）">
        </a-input>
      </a-form-model-item>
      <div class='flex-j-c' style='width: 100%;margin-top:12px'>
        <a-button @click='$emit("close")'>取消</a-button>
        <a-button style="margin-left:32px;" type="primary" @click="handleUpdateGroup('groupForm')" :loading="groupFormLoading">确认</a-button>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { SHOPID } from '@/constants'
import { shop } from '@/api'
import { getSession } from '@/utils/session'
const checkInteger = (rule, value, callback) => {
  if (value) {
    const reg = /^[0-9]*[1-9][0-9]*$/
    if (reg.test(value) && value > 0 && value < 1001) {
      callback()
    } else {
      return callback(new Error('请输入1-1000的正整数'))
    }
  } else {
    callback()
  }
}
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    type: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      groupFormLoading: false,
      rules: {
        name: [
          { required: true, message: '请输入分组名', trigger: 'blur' },
          { max: 32, message: '最多32位', trigger: 'blur' }
        ],
        seqNum: [{ validator: checkInteger, trigger: 'change' }]
      }
    }
  },
  methods: {
    async handleUpdateGroup (ref) {
      this.$refs[`${ref}`].validate(async (valid) => {
        if (valid) {
          this.groupFormLoading = true
          if (this.data.id) {
            this.data.shopMaterialGroupId = this.data.id
          }
          const { msg, code } = await shop.updateGroup({
            ...this.data,
            shopId: getSession(SHOPID),
            type: this.type
          })
          this.groupFormLoading = false
          if (code === '00000') {
            this.$message.success('保存成功')
            this.$emit('success')
          } else {
            this.$message.error(msg || '添加失败')
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.group-form {
  padding: 16px;
  ::v-deep .ant-form-item {
    margin-bottom: 4px;
  }
}
.flex-j-c {
  display: flex;
  justify-content: center;
}
</style>
