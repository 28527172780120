<template>
  <div>
    <a-tabs v-model='current'>
      <a-tab-pane key="0" tab='图片素材'>
      </a-tab-pane>
      <a-tab-pane key="1" tab='视频素材'>
      </a-tab-pane>
    </a-tabs>
    <div v-if='current === "0"' class='content'>
      <ImageResource></ImageResource>
    </div>
    <div v-else>
      <VideoResource></VideoResource>
    </div>
  </div>
</template>
<script>
import ImageResource from './PageImageResource'
import VideoResource from './PageVideoResource'
export default {
  components: {
    ImageResource,
    VideoResource
  },
  data () {
    return {
      current: '0'
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  height: calc( 100vh - 210px );
}
</style>
