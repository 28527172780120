<template>
  <div class='content'>
    <div class='content-group'>
      <div style='height: calc( 100% - 56px )'>
        <div class='group-title'>分组</div>
        <div class='group-list' v-if="groupList.length">
          <div v-for='item in groupList' :key='item.id' @click='changeGroupTab(item.id,item.officeFlag)' class='group-item' :class='{active:currentGroupId === item.id}'>
            {{item.name}}
            <span v-if='item.officeFlag' style='font-size:12px;'>(官方)</span>
          </div>
        </div>
        <div v-else style='line-height: 32px;color:#999;text-align:center;margin-top:80px;'>暂无分组</div>
      </div>
      <div style='width: 164px;height:32px;margin-top:24px;'>
        <a-popover title="" trigger="click" placement="bottom" v-model='popover'>
          <template slot="content">
            <GroupForm :data='groupForm' @close='popover=false' @success='saveGroupSuccess'></GroupForm>
          </template>
          <a-button icon='plus-circle' type='link' @click='groupForm = {}'>新建</a-button>
        </a-popover>
        <a-button icon='setting' type='link' :disabled='groupList.length===0' @click='showGroupManagement = true'>管理</a-button>
      </div>
    </div>
    <div class='content-content'>
      <div class='content-title flex-a-c flex-j-b ' v-if='!officeFlag'>
        <div>
          <a-checkbox class='name' :disabled='list.length===0' :indeterminate='selectCurrentPageNum>0 && selectCurrentPageNum!==list.length' @change="onSelectAll" :checked="selectCurrentPageNum === list.length && selectCurrentPageNum!==0">
            全选
          </a-checkbox>
          <a-button type='primary' style='margin: 0 16px' @click='showSelectGroup = true' :disabled='selectImgData.length===0'>切换分组</a-button>
          <a-button type='primary' :disabled='selectImgData.length===0' @click='deleteSelectedResource'>删除</a-button>
        </div>
        <div class='flex-a-c'>
          <div class='tips'>单个文件大小不超过10M </div>
          <a-upload :show-upload-list="false" name="file" :multiple="true" accept="image/*" :before-upload="beforeUploadImg">
            <a-button icon="plus" type="primary" :loading="addLoading" :disabled='currentGroupId===""'>上传图片</a-button>
          </a-upload>
        </div>
      </div>
      <a-spin class='content-container' :spinning='contentLoading'>
        <div class='img-list' v-if='list.length>0' v-viewer>
          <div class='img-item' v-for='(item) in list' :key='item.shopMaterialId'>
            <base-img :src='item.picPath' class='img'></base-img>
            <div class='btn-box'>
              <a-checkbox v-if='!officeFlag' class='name' @change="onSelectImg($event,item)" :checked="selectImgData.findIndex(x=>x === item.shopMaterialId) !== -1">
                {{item.name}}
              </a-checkbox>
              <div v-else class='name'>{{item.name}}</div>
              <div class='btns' v-if='!officeFlag'>
                <a-popover :visible="currentResouce.shopMaterialId === item.shopMaterialId" trigger='click'>
                  <div slot="content" class='flex-a-c'>
                    <a-input maxlangth='32' style='width:260px' v-model='currentResouce.name' placeholder='请输入'></a-input>
                    <a-button style="margin:0 12px;" size='small' @click='currentResouce = {}'>取消</a-button>
                    <a-button type='primary' @click='updateName' size='small'>保存</a-button>
                  </div>
                  <div class='button' @click='changeName(item)'>改名</div>
                </a-popover>
                <a-popover>
                  <div slot="content" class='flex-a-c'>
                    <div>{{baseUrl}}{{item.picPath}}</div>
                    <a-button type='link' v-clipboard:copy="baseUrl+item.picPath" v-clipboard:success="copyUrl" v-clipboard:error="copyUrlError">复制</a-button>
                  </div>
                  <div class='button'>链接</div>
                </a-popover>
                <div class='button' @click='changeGroup(item)'>分组</div>
                <div class='button' @click='deleteImgResource(item)'>删除</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else style='margin-top: 100px'>
          <a-empty description='暂无图片素材' />
        </div>
      </a-spin>
      <div class='content-pagination'>
        <a-pagination :show-total="total => `共 ${total} 条`" show-less-items show-quick-jumper size="small" v-model="current" :pageSize='pageSize' :total="total" @change='changePage' @showSizeChange="onShowSizeChange" />
      </div>
    </div>
    <a-modal :maskClosable='false' v-model="showGroupManagement" title="分组管理" :footer='null'>
      <div class='group-m-list'>
        <div v-for='item in groupList.filter(x=>!x.officeFlag)' :key='item.id'>
          <div class='flex-j-b flex-a-c group-m-item'>
            <div>{{item.name}}</div>
            <div>
              <a-popover title="" trigger="click" :visible='item.id === groupForm.id' placement="right">
                <template slot="content">
                  <GroupForm :data='groupForm' @close='groupForm={}' @success='saveGroupSuccess'></GroupForm>
                </template>
                <a-button icon='edit' type='link' @click='editGroup(item)'>编辑</a-button>
              </a-popover>
              <a-button icon='delete' type='link' @click='deleteGroup(item)'>删除</a-button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal :confirmLoading='selectGroupLoading' v-model="showSelectGroup" :width='380' title="切换分组" @ok="handleChangeGroup">
      <div v-for='item in groupList.filter(x=>!x.officeFlag)' :key='item.id' @click='selectGroupId = item.id' >
        <div v-if='!item.officeFlag' class='select-group-item flex-a-c flex-j-b' :class='{active: selectGroupId === item.id}'>
          <div>{{item.name}}</div>
          <a-icon class='active' type="check" v-if='selectGroupId === item.id' />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { shop } from '@/api'
import ossUpload from '@/utils/oss'
import GroupForm from './GroupForm'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  name: 'resourceImg',
  components: {
    GroupForm
  },
  data () {
    return {
      popover: false,
      uploadLoading: false,
      groupList: [],
      currentGroupId: '',
      groupForm: {},
      current: 1,
      pageSize: 21,
      total: 0,
      list: [],
      contentLoading: false,
      showGroupManagement: false,
      showSelectGroup: false,
      selectGroupId: '',
      selectGroupLoading: false,
      fileNum: 0,
      addLoading: false,
      selectImgData: [],
      selectCurrentPageNum: 0,
      selectShopMaterialId: '',
      baseUrl: process.env.VUE_APP_IMG,
      currentResouce: {},
      officeFlag: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    saveGroupSuccess () {
      this.popover = false
      this.groupForm = {}
      this.getGroup()
    },
    async init () {
      await this.getGroup()
      if (this.currentGroupId) {
        this.getResourcePage()
      }
    },
    async getResourcePage () {
      this.contentLoading = true
      const { data, msg, code, page } = await shop.getResourcePage({
        current: this.current,
        size: this.pageSize,
        groupId: this.currentGroupId,
        officeFlag: this.officeFlag
      })
      this.contentLoading = false
      if (code === '00000') {
        this.list = data
        this.total = page.total
        this.current = page.pageNo
        this.pageSize = page.pageSize
        this.selectImgData = []
        this.selectCurrentPageNum = 0
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    editGroup (item) {
      this.groupForm = Object.assign({}, item)
    },
    onShowSizeChange (current, pageSize) {
      this.current = current
      this.pageSize = pageSize
      this.getResourcePage()
    },

    async getGroup () {
      const { data, msg, code } = await shop.getSourceGroup()
      if (code === '00000') {
        if (data.length) {
          this.groupList = data
          if (!this.currentGroupId && data.length) {
            this.currentGroupId = data[0].id
            this.officeFlag = data[0].officeFlag
          }
        }
      } else {
        this.$message.error(msg || '获取分组数据失败')
      }
    },
    deleteImgResource ({ shopMaterialId, name }) {
      this.$confirm({
        title: '删除提示',
        content: `确定要删除【${name}】吗？`,
        onOk: async () => {
          const { msg, code } = await shop.deleteImgResource({
            shopMaterialId
          })
          if (code === '00000') {
            this.$message.success('删除成功')
            if (this.selectImgData.indexOf(shopMaterialId) !== -1) {
              this.selectCurrentPageNum--
              this.selectImgData.splice(this.selectImgData.indexOf(shopMaterialId), 1)
            }
            this.getResourcePage()
          } else {
            this.$message.error(msg || '删除失败')
          }
        }
      })
    },
    deleteSelectedResource () {
      this.$confirm({
        title: '删除提示',
        content: `确定要删除这${this.selectImgData.length}张图片吗？`,
        onOk: async () => {
          const { msg, code } = await shop.deleteImgResource({
            shopMaterialId: this.selectImgData.join(',')
          })
          if (code === '00000') {
            this.$message.success('删除成功')
            this.selectImgData = []
            this.selectCurrentPageNum = 0
            this.getResourcePage()
          } else {
            this.$message.error(msg || '删除失败')
          }
        }
      })
    },
    deleteGroup (item) {
      this.$confirm({
        title: '删除提示',
        content: `确认要删除分组【${item.name}】吗？`,
        onOk: async () => {
          const { msg, code } = await shop.deleteGroup({
            shopMaterialGroupId: item.id
          })

          if (code === '00000') {
            this.$message.success('删除成功')
            if (this.currentGroupId === item.id) {
              this.currentGroupId = ''
            }
            this.init()
          } else {
            this.$message.error(msg || '删除失败')
          }
        }
      })
    },
    beforeUploadImg (file, fileList) {
      this.fileNum++
      if (this.fileNum === fileList.length) {
        const isLt10M = file.size / 1024 / 1024 < 10
        if (!isLt10M) {
          this.$message.error('单个文件大小不得超过10M!')
        } else {
          this.handleCustomRequest(fileList)
        }
        this.fileNum = 0
      }
      return false
    },
    changePage (e) {
      this.current = e
      this.getResourcePage()
    },
    changeGroup (obj) {
      this.selectShopMaterialId = obj.shopMaterialId
      this.showSelectGroup = true
    },
    async handleChangeGroup () {
      this.selectGroupLoading = true
      const { code, msg } = await shop.imgChangeGroup({
        shopMaterialGroupId: this.selectGroupId,
        shopMaterialId: this.selectShopMaterialId
          ? this.selectShopMaterialId
          : this.selectImgData.join(',')
      })
      this.selectGroupLoading = false
      if (code === '00000') {
        if (!this.selectShopMaterialId) {
          this.selectImgData = []
          this.selectCurrentPageNum = 0
        }
        this.showSelectGroup = false
        this.selectGroupId = ''
        this.selectShopMaterialId = ''
        this.$message.success('切换成功')
        this.getResourcePage()
      } else {
        this.$message.error(msg || '切换失败')
      }
    },
    changeGroupTab (id, officeFlag) {
      this.officeFlag = officeFlag
      this.currentGroupId = id
      this.current = 1
      this.getResourcePage()
    },
    async handleCustomRequest (files) {
      try {
        this.addLoading = true
        const data = await ossUpload(files)
        const { code, msg } = await shop.saveImgResource(
          data.map((x) => {
            return {
              name: x.name,
              picPath: x.url,
              shopMaterialGroupId: this.currentGroupId,
              shopId: getSession(SHOPID)
            }
          })
        )
        this.addLoading = false
        if (code === '00000') {
          this.current = 1
          this.getResourcePage()
        } else {
          this.$message.error(msg || '上传失败，请重试')
        }
      } catch (e) {
        this.$message.warn('上传失败')
      } finally {
      }
    },
    async resourceUpdate (arr) {
      const res = await shop.saveImgResource(arr)
      if (res.code === '00000') {
        this.current = 1
        this.getResourcePage()
      } else {
        this.$message.error(res.msg || '上传失败，请重试')
      }
    },
    onSelectImg (e, obj) {
      const index = this.selectImgData.findIndex((x) => x === obj.shopMaterialId)
      // 选中
      if (e.target.checked) {
        this.selectImgData.push(obj.shopMaterialId)
      } else {
        this.selectImgData.splice(index, 1)
      }
      this.getSelectCurrentPageNum()
    },
    onSelectAll (e) {
      if (e.target.checked) {
        this.list.forEach((item) => {
          if (this.selectImgData.indexOf(item.shopMaterialId) === -1) {
            this.selectImgData.push(item.shopMaterialId)
          }
        })
      } else {
        this.list.forEach((item) => {
          if (this.selectImgData.indexOf(item.shopMaterialId) !== -1) {
            this.selectImgData.splice(this.selectImgData.indexOf(item.shopMaterialId), 1)
          }
        })
      }
      this.selectCurrentPageNum = this.selectImgData.length
    },
    getSelectCurrentPageNum () {
      let num = 0
      if (this.selectImgData.length === 0) {
        this.selectCurrentPageNum = 0
      } else {
        this.selectImgData.forEach((item) => {
          this.list.forEach((val) => {
            if (item === val.shopMaterialId) {
              num++
            }
          })
        })
        this.selectCurrentPageNum = num
      }
    },
    copyUrl (url) {
      this.$message.success('复制成功')
    },
    copyUrlError () {
      this.$message.error('复制失败')
    },
    changeName (obj) {
      this.currentResouce = Object.assign({}, obj)
    },
    updateName () {
      this.resourceUpdate([this.currentResouce])
      this.currentResouce = {}
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  height: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  .content-group {
    width: 196px;
    flex-shrink: 0;
    margin-right: 16px;
    background: #fff;
    box-shadow: 0 4px 8px #ddd;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .group-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 18px;
      margin-bottom: 16px;
    }
    .group-list {
      height: calc(100% - 34px);
      overflow: auto;
      .group-item {
        color: #333;
        margin-right: 16px;
        line-height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          color: @main-color;
        }
        &.active {
          color: @main-color;
          font-weight: 900;
        }
      }
    }
  }

  .content-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 4px 8px #ddd;
    background: #fff;
    padding: 12px 16px;
    .content-title {
      font-size: 18px;
      margin-bottom: 12px;
      .tips {
        font-size: 14px;
        color: #999;
        margin-right: 16px;
      }
    }
    .content-container {
      flex:1;
      overflow: auto;
      padding: 16px 0;
      box-sizing: border-box;
      .img-list {
        display: flex;
        flex-wrap: wrap;
        .img-item {
          width: calc( (100vw - 620px) / 7 );
          min-width: 72px;
          position: relative;
          margin: 0 8px;
          background: #fff;
          margin-bottom: 16px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          box-shadow: 0 4px 8px #ddd;
          transition: 0.3s;
          &:hover .img ::v-deep img {
            transform: scale(1.1);
          }
          .img {
            width: calc( (100vw - 620px) / 7 );
            height: calc( (100vw - 620px) / 7 );
            min-width: 72px;
            min-height: 72px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: #eee;
            ::v-deep img {
              width: 100%;
              object-fit: cover;
              transition: 0.3s;
            }
          }
          .btn-box {
            font-size: 12px;
            .name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 8px;
              width: 100%;
              box-sizing: border-box;
              font-size: 12px;
              line-height: 24px;
            }
            .btns {
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              .button {
                font-size: 12px;
                line-height: 24px;
                color: @main-color;
              }
            }
          }
        }
      }
    }
    .content-pagination {
      padding-top: 8px;
      text-align: right;
      height: 32px;
      line-height: 32px;
    }
  }
}

.group-m-list {
  max-height: 480px;
  overflow: auto;
}
.group-m-item {
  height: 44px;
  border-bottom: 1px solid #eee;
}
.group-m-item:last-child {
  border: none;
}
.img-opt-item {
  line-height: 32px;
  cursor: pointer;
  &:hover {
    color: @main-color;
  }
}
.select-group-item {
  height: 44px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  .active {
    color: @main-color;
  }
  &.active {
    color: @main-color;
  }
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: @main-color;
  }
}
.flex-a-c {
  display: flex;
  align-items: center;
}
.flex-j-b {
  display: flex;
  justify-content: space-between;
}
.flex-j-e {
  display: flex;
  justify-content: flex-end;
}
</style>
